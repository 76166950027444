@import 'colors.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#skills{
    
    position: relative;
    top: 40vh;
    padding-top: 15vh;
}

.topics{
    position: relative;
    top: 10vh;
    color: var(--white);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}

.bg{
    position: relative;
    height: 15px;
    width: 100%;
    background-color: var(--white);
    border-radius: 25px;
}



.skills {
	
	position: absolute;
    height: 15px;
    animation-delay: 01s ;
    background-color: var(--blue);
    border-radius: 25px;
    animation: progress 1s ease-in-out forwards;
    opacity: 0;
}

@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}