@import 'colors.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#contact{
    
    display: block;
    position: relative;
    top: 70vh;
    padding-top: 15vh;
}

img, svg{
    color: var(--blue);
    font-size: 35px;
}

.contact-content{
    font-family: 'Poppins', sans-serif;
}
.form-floating{
    color: var(--white);
    font-size: 20px;
    background: none;
   
}
textarea,
input{
    background: none!important;
    border: none!important;
    border-radius: 0px!important;
    border-bottom: 1px solid white!important;
    color: var(--white)!important;
    outline: none;
    padding-top: 2rem!important;
    
}

textarea,
input:focus{
    border: none;
    outline: none;
    box-shadow: none!important;
}

.footer{
    opacity: 0.5;

}